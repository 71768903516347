<template>
  <div class="view-container m-0">
    <el-form
        :model="form"
        class="lh-15 label-nowrap"
        label-width="140px"
    >
      <div class="view">
        <div class="view-body">
          <el-form-item
              :class="{'input': true, 'has-error': backend_errors.Typ}"
              :label="$t('system.invoice_type')"
              class="lb-right"
          >
            <el-select
                :disabled="(getMode === 'edit')? true : false"
                :readonly="(getMode === 'edit')? true : false"
                filterable
                v-model="form.Typ"
            >
              <el-option
                  :value="key"
                  v-bind:key="key"
                  v-for="(itype, key) in option.invoice_type"
              >
                {{ itype }}
              </el-option>
            </el-select>
          </el-form-item>
          <div class="row">
            <div class="col-md-3">
              <label class="lb-left">{{ $t('invoice.dealer') }}:</label>
              <el-form-item
                  :label="$t('invoice.client_name')"
              >
                <el-input
                    disabled
                    name="client_name"
                    v-model="setting.client_name"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.nip')"
              >
                <el-input
                    disabled
                    name="client_name"
                    v-model="setting.nip"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.street')"
              >
                <el-input
                    disabled
                    name="client_name"
                    v-model="setting.street"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.post_code')"
              >
                <el-input
                    disabled
                    name="client_name"
                    v-model="setting.post_code"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.city')"
              >
                <el-input
                    disabled
                    name="client_name"
                    v-model="setting.city"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-4">
              <label class="lb-left">{{ $t('invoice.recipient') }}:</label>
              <el-form-item
                  :label="$t('invoice.recipient_nip')"
                  required
              >
                <el-input
                    :class="backend_errors.NIP ? 'validate-error' : ''"
                    name="NIP"
                    v-mask="'##########'"
                    v-model="form.NIP"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.recipient_nazw')"
                  required
              >
                <el-input
                    :class="backend_errors.nazw ? 'validate-error' : ''"
                    name="nazw"
                    v-model="form.nazw"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.recipient_ulic')"
                  required
              >
                <el-input
                    :class="backend_errors.Ulic ? 'validate-error' : ''"
                    name="Ulic"
                    v-model="form.Ulic"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.recipient_kodp')"
                  required
              >
                <el-input
                    :class="backend_errors.Kodp ? 'validate-error' : ''"
                    name="Kodp"
                    v-model="form.Kodp"
                ></el-input>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.recipient_mias')"
                  required
              >
                <el-input
                    :class="backend_errors.Mias ? 'validate-error' : ''"
                    name="Mias"
                    v-model="form.Mias"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-5">
              <el-form-item
                  :label="$t('invoice.recipient_dwy')"
                  required
                  style="margin-top: 40px;"
                  v-show="form.Typ === 'VAT' || form.Typ === 'PRO'"
              >
                <el-date-picker
                    :class="backend_errors.DWy ? 'validate-error' : ''"
                    :disabled="(getMode === 'edit')? true : false"
                    :picker-options="pickerOptionsDWy"
                    :placeholder="$t('invoice.recipient_dwy')"
                    :readonly="(getMode === 'edit')? true : false"
                    name="DWy"
                    type="date"
                    v-model="form.DWy"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :label="$t('invoice.recipient_dsp')"
                  required
                  v-show="form.Typ === 'VAT' || form.Typ === 'PRO'"
              >
                <el-date-picker
                    :class="backend_errors.DSp ? 'validate-error' : ''"
                    :disabled="(getMode === 'edit')? true : false"
                    :picker-options="pickerOptionsDSp"
                    :placeholder="$t('invoice.recipient_dsp')"
                    :readonly="(getMode === 'edit')? true : false"
                    name="DSp"
                    type="date"
                    v-model="form.DSp"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :class="{'input': true, 'has-error': front_errors.has('Tpl') || backend_errors.Tpl }"
                  :label="$t('invoice.recipient_tpl')"
                  required
                  v-show="form.Typ === 'VAT' || form.Typ === 'PRO'"
              >
                <el-date-picker
                    :class="backend_errors.Tpl ? 'validate-error' : ''"
                    :disabled="(getMode === 'edit')? true : false"
                    :picker-options="pickerOptionsTpl"
                    :placeholder="$t('invoice.recipient_tpl')"
                    :readonly="(getMode === 'edit')? true : false"
                    name="Tpl"
                    type="date"
                    v-model="form.Tpl"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table--wrapper table-invoce">
                <table class="table table--wrapper mt-3 mb-3">
                  <thead>
                  <tr>
                    <th><span class="required-th">{{ $t('report.nazw') }}</span></th>
                    <th>{{ $t('report.il') }}</th>
                    <th>{{ $t('report.jm') }}</th>
                    <th>{{ $t('report.prrab') }}</th>
                    <th>{{ $t('report.before_discount') }}</th>
                    <th>{{ $t('report.cn') }}</th>
                    <th>{{ $t('report.value_cn') }}</th>
                    <th>{{ $t('report.nvat') }}</th>
                    <th>{{ $t('report.vat') }}</th>
                    <th>{{ $t('report.total') }}</th>
                    <th style="min-width: auto;"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-bind:key="index"
                      v-for="(item, index) in form.items"
                  >
                    <td>
                      <el-form-item required>
                        <el-input
                            :class="backend_errors['items.' + [index] + '.Nazw'] ? 'validate-error' : ''"
                            name="text"
                            style="min-width: 350px;"
                            type="textarea"
                            v-model="item.Nazw"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-input-number
                            :controls="false"
                            :min="0"
                            controls-position="right"
                            v-bind:value="item.il ? item.il : 0"
                            v-mask="'#########'"
                            v-model="item.il"
                        ></el-input-number>
                      </el-form-item>
                    </td>

                    <td>
                      <el-form-item>
                        <el-select
                            :append-to-body="false"
                            filterable
                            v-model="item.JM"
                        >
                          <el-option
                              :value="key"
                              v-bind:key="key"
                              v-for="(itype, key) in option.jms"
                          >
                            {{ itype }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </td>

                    <td>
                      <!--<el-form-item>-->
                      <!--&lt;!&ndash;<div class="el-input-number">&ndash;&gt;-->
                      <!--&lt;!&ndash;<div class="el-input">&ndash;&gt;-->
                      <!--&lt;!&ndash;<input&ndash;&gt;-->
                      <!--&lt;!&ndash;min="0"&ndash;&gt;-->
                      <!--&lt;!&ndash;max="100"&ndash;&gt;-->
                      <!--&lt;!&ndash;step="0.01"&ndash;&gt;-->
                      <!--&lt;!&ndash;type="number"&ndash;&gt;-->
                      <!--&lt;!&ndash;class="el-input__inner pl-1 pr-1"&ndash;&gt;-->
                      <!--&lt;!&ndash;v-bind:value="numberPrecision(item.PrRab)"&ndash;&gt;-->
                      <!--&lt;!&ndash;v-on:input="item.PrRab = numberPrecisionMaxNumber($event.target.value)"&ndash;&gt;-->
                      <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                      <!--&lt;!&ndash;&lt;!&ndash;<input class="el-input__inner" type="number" min="0" step="0.01" v-model="item.Cena"/>&ndash;&gt;&ndash;&gt;-->
                      <!--&lt;!&ndash;</div>&ndash;&gt;-->
                      <!--&lt;!&ndash;</div>&ndash;&gt;-->
                      <!--&lt;!&ndash;<el-input type="number" v-bind:value="numberPrecisionMaxNumber(item)" v-model="item.PrRab"></el-input>&ndash;&gt;-->
                      <!--&lt;!&ndash;<el-input pattern="^(?!00)[0-9]{2}$"  v-bind:value="item.PrRab ? item.PrRab : 0" v-model="item.PrRab"></el-input>&ndash;&gt;-->
                      <!--<el-input-number-->
                      <!--:min="0"-->
                      <!--:max="100"-->
                      <!--:controls="false"-->
                      <!--v-mask="'###'"-->
                      <!--:value="item.PrRab ? item.PrRab : 0"-->
                      <!--v-model="item.PrRab"></el-input-number>-->
                      <!--</el-form-item>-->
                      <el-form-item>
                        <!--<el-input placeholder="Please input" id="floatTextBox" v-model="item.PrRab" v-on:input"filterNumber(item)" @focus="filterNumberFocus"></el-input>-->
                        <div class="el-input-number">
                          <div class="el-input">
                            <input
                                class="el-input__inner pl-1 pr-1"
                                v-model="item.PrRab"
                                v-on:input="filterNumber(item, 'PrRab', $event, 100)"
                            >
                            <!--<input-->
                            <!--min="0"-->
                            <!--max="100"-->
                            <!--type="number"-->
                            <!--class="el-input__inner pl-1 pr-1"-->
                            <!--v-model="item.PrRab"-->
                            <!--v-on:input="beforeDiscount(item)">-->
                            <!--<input v-model="item.PrRab" v-on:input="beforeDiscount"  type="text">-->
                          </div>
                        </div>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <div class="el-input-number">
                          <div class="el-input">
                            <input
                                class="el-input__inner pl-1 pr-1"
                                v-model="item.Cena"
                                v-on:input="filterNumber(item, 'Cena', $event)"
                            >
                            <!--<input-->
                            <!--min="0"-->
                            <!--type="number"-->
                            <!--class="el-input__inner pl-1 pr-1"-->
                            <!--v-bind:value="numberPrecision(item.Cena)"-->
                            <!--v-on:input="item.Cena = numberPrecision($event.target.value)"-->
                            <!--&gt;-->
                            <!--<input class="el-input__inner" type="number" min="0" step="0.01" v-model="item.Cena"/>-->
                          </div>
                        </div>
                        <!--<el-input type="number" min="0" step="0.01" v-model.number="item.Cena"></el-input>-->
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-input
                            disabled
                            readonly
                            type="number"
                            v-bind:value="getUnit(item)"
                            v-model="item.CN"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-input
                            disabled
                            readonly
                            type="number"
                            v-bind:value="getValueCN(item)"
                            v-model="item.valueCN"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-select
                            filterable
                            v-model="item.VAT"
                        >
                          <el-option
                              :value="key"
                              v-bind:key="key"
                              v-for="(itype, key) in option.vats"
                          >
                            {{ itype }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-input
                            disabled
                            readonly="readonly"
                            type="number"
                            v-bind:value="getVAT(item)"
                            v-model="item.valueVAT"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item>
                        <el-input
                            class="form-control"
                            disabled
                            readonly="readonly"
                            type="number"
                            v-bind:value="getCenaB(item)"
                            v-model.lazy="item.cenab"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td>
                      <font-awesome-icon
                          @click="remove(form.items, index)"
                          icon="trash"
                          style="color: #F56C6C;"
                      />
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr></tr>
                  <tr>
                    <td style="text-align: left;">
                      <el-button
                          @click.stop="addLine"
                          type="primary"
                      >
                        {{ $t('system.add_line') }}
                        <!--<font-awesome-icon  style="color: #fff;" icon="plus-circle"/>-->
                      </el-button>
                    </td>
                  </tr>
                  <tr style="border-bottom: 1px solid #dee2e6;">
                    <td colspan="5">
                    </td>
                    <td class="brd-t">{{ $t('report.total') }}</td>
                    <td class="brd-t">{{ getTotalCN | numberChangeSeparator }}</td>
                    <td class="brd-t"></td>
                    <td class="brd-t">{{ getTotalVAT | numberChangeSeparator }}</td>
                    <td class="brd-t">{{ getTotal | numberChangeSeparator }}</td>
                    <td class="brd-t"></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <el-form-item
              :class="{'input': true, 'has-error': front_errors.has('Bank') || backend_errors.Bank}"
              :label="$t('invoice.bank')"
          >
            <el-input
                name="Bank"
                v-model="setting.Bank"
            ></el-input>
          </el-form-item>
          <el-form-item
              :class="{'input': true, 'has-error': front_errors.has('BankN') || backend_errors.BankN }"
              :label="$t('invoice.bankn')"
          >
            <el-input
                name="BankN"
                v-mask="'## #### #### #### #### #### ####'"
                v-model="setting.BankN"
            ></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('invoice.fp')"
              required
          >
            <el-select
                :class="backend_errors.Fp ? 'validate-error' : ''"
                filterable
                name="Fp"
                style="width: 100%"
                v-model="form.Fp"
            >
              <el-option
                  :value="key"
                  v-bind:key="key"
                  v-for="(itype, key) in option.payment_forms"
              >
                {{ itype }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              :class="{'input': true, 'has-error': front_errors.has('note') }"
              :label="$t('invoice.note')"
          >
            <el-input
                name="note"
                v-model="form.note"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div
          class="view"
          v-if="time_entries && time_entries.length > 0"
      >
        <div class="view-body">
          <el-checkbox
              v-bind:label="$t('invoice.show_attachment')"
              v-if="time_entries"
              v-model="form.show_attachment"
          >
          </el-checkbox>
        </div>
      </div>
      <div
          class="view"
          v-if="time_entries && time_entries.length > 0"
      >
        <div class="view-heading">
          <div class="view-title">
            {{ $t('system.attachment') }}
          </div>
        </div>
        <div class="view-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive no-padding">
                <table class="table table-bordered table-hover no-pointer">
                  <thead>
                  <tr>
                    <th style="width: 20%">{{ $t('report.project_name') }}</th>
                    <th style="width: 20%">{{ $t('report.issue_number') }}</th>
                    <!--<th style="width: 10%">{{ $t('report.user') }}</th>-->
                    <!--<th style="width: 10%" v-if="!modules['activity']">{{ $t('report.activity') }}</th>-->
                    <!--<th style="width: 10%">{{ $t('report.issue_status') }}</th>-->
                    <th style="width: 5%">{{ $t('report.time_entires_created') }}</th>
                    <th style="width: 5%">{{ $t('report.time_entires_hours') }}</th>
                    <!--<th>{{ $t('report.date_settled') }}</th>-->
                    <th style="width: 30%">{{ $t('report.comments') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="time_entries">
                    <tr
                        v-bind:key="path"
                        v-for="(view, path) in time_entries"
                    >
                      <td>
                        <router-link :to="'/project/' + view.projectID">
                          {{ view.projectName ? view.projectName : '' }}
                        </router-link>
                      </td>
                      <td>
                        #{{ view.time_entriesIssueID ? view.time_entriesIssueID : '' }}
                        {{ view.issueSubject ? view.issueSubject : '' }}
                      </td>
                      <!--<td>{{view.timeEntryUserFirstName ? view.timeEntryUserFirstName + ' ' + view.timeEntryUserLastName : ''}}</td>-->
                      <!--<td> {{view.activityName ? view.activityName : ''}}</td>-->
                      <!--<td>{{view.statusesName ? view.statusesName : ''}}</td>-->
                      <td>{{ view.time_entriesSpentOn ? view.time_entriesSpentOn : '' }}</td>
                      <td>{{ view.time_entriesOverworkedTime ? view.time_entriesOverworkedTime : '' }}</td>
                      <!--<td>-->
                      <!--{{view.time_entriesSettledInternal ? view.time_entriesSettledInternal : ''}}-->
                      <!--</td>-->
                      <td>{{ view.time_entriesComments ? view.time_entriesComments : '' }}</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="view">
        <div class="view-body d-flex justify-content-end">
          <el-button
              @click.prevent.stop="onSave"
              type="primary"
          >{{ $t('system.save') }}
          </el-button>
          <el-button @click.prevent.stop="backTo">{{ $t('system.cancel') }}</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
import {mapGetters} from 'vuex';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'InvoiceForm',
  filters: {
    numberChangeSeparator: function (number) {
      return number.toString().replace('.', ',');
    },
    limit: function (number) {
      return number > 100 ? 100 : number;
    },
  },
  data: function () {
    var vm = this;
    return {
      form: {
        Wart: 0,
        PrRab: 0,
        CB: 0,
        il: 1,
        CenaCB: 0,
        cenab: 0,
        items: [],
        Cena: 0,
        valueCN: 0,
        valueVAT: 0,
        totalCN: 0,
        totalCB: 0,
        totalVAT: 0,
      },
      time_entries: [],
      inputRequiredEmpty: [],
      project_details: {},
      disabledDays: null,
      disabledDSp: null,
      disabledTpl: null,
      backend_errors: {},
      option: {
        customers: [],
      },
      title: 'Create',
      initialize: 'invoice/create',
      redirect: '/invoice',
      store: 'invoice',
      method: 'post',
      modules: [],
      mode: {},
      setting: {
        Bank: null,
        BankN: null,
      },
      pickerOptionsDWy: {
        disabledDate: function (time) {
          if (vm.form.Typ === 'VAT') {
            let tmp = moment(time).format('YYYY-MM-DD');
            return vm.disabledDays.indexOf(tmp) > -1;
          }
        },
      },
      pickerOptionsDSp: {
        disabledDate: function (time) {
          let minDate = moment(vm.form.DWy).subtract(15, 'days').format('YYYY-MM-DD');
          let tmp = moment(time).format('YYYY-MM-DD');
          return tmp < minDate;
        },
      },
      pickerOptionsTpl: {
        disabledDate: function (time) {
          let Dsp = moment(vm.form.DSp).format('YYYY-MM-DD');
          let tmp = moment(time).format('YYYY-MM-DD');
          return tmp < Dsp;
        },
      },
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === 'edit') {
      this.title = 'Edit';
      this.initialize = 'invoice/' + this.$route.params.invoiceId + '/edit';
      this.store = 'invoice/' + this.$route.params.invoiceId;
      this.method = 'put';
    }
    if (this.$route.meta.mode === 'createFV') {
      this.title = 'Create by Invoice';
      this.initialize = 'invoice/' + this.$route.params.invoiceId + '/create';
      this.store = 'invoice';
      this.method = 'post';
    }
    this.fetchData();
  },
  mounted() {
    let vm = this;
    vm.modules = vm.$store.getters.modules;
  },
  watch: {
    '$route': 'fetchData',
  },
  computed: {
    ...mapGetters([
      'invoice_items',
    ]),
    subTotal() {
      return this.form.items.reduce(function (carry, item) {
        return carry + parseFloat(item.il) * parseFloat(item.CN);
      }, 0).toFixed(2);
    },
    getTotal: function () {
      let vm = this;
      let total = this.form.items.reduce(function (carry, item) {
        if (item.cenab === '') {
          item.cenab = 0;
        }
        return carry + parseFloat(item.cenab);
      }, 0);
      vm.form.Wart = parseFloat(total).toFixed(2);
      return parseFloat(total).toFixed(2);
    },
    getTotalCN() {
      let totalCn = this.form.items.reduce(function (carry, item) {
        if (item.CN === '') {
          item.CN = 0;
        }
        if (item.il === '') {
          item.il = 0;
        }
        return carry + parseFloat(parseFloat(item.il) * parseFloat(item.CN));
      }, 0);
      return parseFloat(totalCn).toFixed(2);
    },
    getTotalCB() {
      let totalCb = this.form.items.reduce(function (carry, item) {
        if (item.CB === '') {
          item.CB = 0;
        }
        if (item.il === '') {
          item.il = 0;
        }
        return carry + parseFloat(parseFloat(item.il) * parseFloat(item.CB));
      }, 0);
      return parseFloat(totalCb).toFixed(2);
    },
    getTotalVAT() {
      let totalVat = this.form.items.reduce(function (carry, item) {
        if (item.CN === '') {
          item.CN = 0;
        }
        if (item.cenab === '') {
          item.cenab = 0;
        }
        if (item.il === '') {
          item.il = 0;
        }
        return carry + parseFloat(item.cenab) - (parseFloat(item.il) * parseFloat(item.CN));
      }, 0);
      return parseFloat(totalVat).toFixed(2);
    },
    getMode: function () {
      return this.$route.meta.mode;
    },
    getTimeEntries: function () {
      if (typeof this.time_entires !== 'undefined') {
        return this.time_entries.length;
      }
      return 0;
    },
  },
  methods: {
    filterNumber(item, current, event, limit) {
      let vm = this;
      vm.setInputFilter(event.target, function (value) {
        if (limit) {
          if (value === limit + ',') return false;
          if (value > limit) return false;
        }
        return /^-?\d*[,]?\d{0,2}$/.test(value);
      }, item, current);
    },
    setInputFilter(textbox, inputFilter, item, current) {
      ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
        textbox.addEventListener(event, function () {
          var regExpZero = /^0[0-9].*$/;
          if (inputFilter(this.value)) {
            let subValue = regExpZero.test(this.value) ? this.value.substr(1) : this.value;
            this.oldValue = subValue;
            item[current] = this.oldValue;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty('oldValue')) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          }
        });
      });
    },
    numberPrecision(number) {
      return _.ceil(number, 2);
    },
    beforeDiscount(item) {
      let beforeNumber = _.toNumber(item.PrRab);
      let numberPrecision = this.numberPrecision(beforeNumber);
      item.PrRab = numberPrecision > 100 ? 100 : numberPrecision;
    },
    isNumberValidation(event) {
      console.log(event); //keyCodes value
      // console.log(string); //keyCodes value
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      // let strNumber = number.toString()
      // let regExp = /^0.\d*$/
      // let regExp2 = /^[1-9]*$/
      // if (regExp.test(strNumber) || regExp2.test(strNumber)) {
      //   console.log('yes');
      // } else {
      //   vm.string = 0
      // }
    },
    backTo: function () {
      window.history.back();
    },
    addLine() {
      this.form.items.push({
        Nazw: '',
        il: 1,
        JM: 'godz',
        CN: 1,
        CB: 0,
        Cena: 12,
        cenab: 2,
        PrRab: '',
        VAT: '23',
        valueCN: 0,
        valueVAT: 0,
      });
    },
    fetchData() {
      const vm = this;
      axios.get(this.initialize, {
        params: {
          invoice_item: vm.invoice_items,
          invoiceId: vm.$route.params.invoiceId,
        },
      })
          .then(function (response) {
            let form = response.data.form;
            if (vm.$route.meta.mode === 'createFV') {
              form.Typ = 'VAT';
            }
            form.show_attachment = (form.show_attachment === 1);
            vm.form = form;
            vm.disabledDays = response.data.disabledDays;
            vm.option = response.data.option;
            vm.setting = response.data.settings;
            vm.time_entries = response.data.time_entries;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    remove(row, index) {
      let vm = this;
      // const swalWithBootstrapButtons = swal.mixin({
      //   confirmButtonClass: 'btn btn-success',
      //   cancelButtonClass: 'btn btn-danger',
      //   buttonsStyling: false
      // });
      swal.fire({
        title: vm.$t('system.if_you_sure'),
        text: vm.$t('system.fileremovaldescription'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          row.splice(index, 1);
        }
      });
    },
    checkinputRequired(name) {
      if (_.includes(this.inputRequiredEmpty, name)) return true;
    },
    getinputRequired() {
      var vm = this;
      var inputs = document.querySelectorAll('.is-required .el-input__inner');
      vm.inputRequiredEmpty = [];
      _.forEach(inputs, function (item) {
        if (item.value.length === 0) vm.inputRequiredEmpty.push(item.name);
      });
    },
    onSave() {
      let vm = this;
      vm.getinputRequired();
      vm.form.time_entries = vm.time_entries;
      let form = vm.form;
      vm.form.invoiceId = vm.$route.params.invoiceId;
      vm.form.Bank = vm.setting.Bank;
      vm.form.BankN = vm.setting.BankN;
      if (vm.form.DWy) {
        vm.form.DWy = moment(form.DWy).format('YYYY-MM-DD');
      }
      if (vm.form.DSp) {
        vm.form.DSp = moment(form.DSp).format('YYYY-MM-DD');
      }
      if (vm.form.Tpl) {
        vm.form.Tpl = moment(form.Tpl).format('YYYY-MM-DD');
      }
      axios[vm.method](vm.store, vm.form)
          .then(function (response) {
            if (response.data.saved) {
              vm.$store.commit('SET_INVOICE_ITEMS', null);
              vm.$router.push(vm.redirect);
            }
          })
          .catch(function (error) {
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 4);
            if (typeof dataErrors !== 'undefined') {
              vm.backend_errors = dataErrors;
            }
            let top = document.body.getElementsByClassName('app-wrapper')[0];
            let scrollStep = -top.scrollTop / (500 / 15);
            let scrollInterval = setInterval(function () {
              if (top.scrollTop !== 0) {
                top.scrollBy(0, scrollStep);
              } else clearInterval(scrollInterval);
            }, 15);
            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
          });
    },
    getCB(item) {
      let cb = 0;
      if (item.VAT === '') {
        item.VAT = 0;
      }
      if (item.il === '') {
        item.il = 0;
      }
      if (item.Cena === '') {
        item.Cena = 0;
      }
      if (item.PrRab === '') {
        item.PrRab = '';
      }
      let cn = parseFloat(item.Cena) - (parseFloat(item.Cena) * parseFloat(item.PrRab) / 100);
      cb = parseFloat(cn) + (parseFloat(cn * parseInt(item.VAT) / 100));
      item.CB = cb;
      return cb;
    },
    getCenaB(item) {
      this.getCB(item);
      if (item.il === '') {
        item.il = 0;
      }
      if (item.CB === '') {
        item.CB = 0;
      }
      let cenab = parseFloat(item.il) * parseFloat(item.CB);
      item.cenab = cenab.toFixed(2);
      return item.cenab;
    },
    getUnit(item) {
      let cn = parseFloat(item.Cena);
      if (item.PrRab === '' || item.PrRab == null) {
        item.PrRab = '';
      }
      if (item.il === '') {
        item.il = 0;
      }
      cn = parseFloat(item.Cena) - (parseFloat(item.Cena) * parseFloat(item.PrRab) / 100);
      item.CN = this.numberPrecision(cn);
      return cn;
    },
    getValueCN(item) {
      if (item.CN === '') {
        item.CN = 0;
      }
      if (item.il === '') {
        item.il = 0;
      }
      item.valueCN = this.numberPrecision(parseFloat(item.il) * parseFloat(item.CN));
      return item.valueCN;
    },
    getVAT(item) {
      if (item.CN === '') {
        item.CN = 0;
      }
      if (item.cenab === '') {
        item.cenab = 0;
      }
      if (item.il === '') {
        item.il = 0;
      }
      item.valueVAT = this.numberPrecision(parseFloat(item.cenab) - (parseFloat(item.il) * parseFloat(item.CN)));
      return item.valueVAT;
    },
    setEvent: function (e) {
      console.log('e', e);
      if (_.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
          // Allow: Ctrl+A, Command+A
          (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right, down, up
          (e.keyCode >= 35 && e.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-form-item {
  margin-bottom: 10px;
}

.el-date-editor {
  width: 100% !important;
}

.lb-left {
  text-align: right;
  padding: 0 12px 0 0;
  width: 140px;
  line-height: 40px;
}

.required-th {
  position: relative;

  &:before {
    content: '*';
    font-size: 14px;
    color: #f56c6c;
    position: absolute;
    left: -10px;
  }
}

.table--threeDots-forOptions:after {
  content: '\2807';
  font-size: 20px;
}

.table--wrapper {
  overflow-x: auto;
}

.table--th-view {
  border: 3px solid white;
  white-space: nowrap
}

.table--cell-fixedRight {
  max-width: 20px;
  padding: 5px 15px 5px 15px;
  background: white;
  border-left: solid 1px black;
  border-right: solid 1px #DDEFEF;
  right: 2% !important;
  position: absolute;
  top: auto;
  width: 120px;
}

.hide-text {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.lb-right label {
  text-align: left;
}

.table-invoce {
  .el-form-item__content {
    margin-left: 0 !important;
  }

  .el-input-number {
    width: 100% !important;
  }

  .el-form-item {
    margin: 0;

    .el-textarea {
      textarea {
        resize: vertical;
        min-height: 40px !important;
        height: 40px;
      }
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

</style>
